import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatasetInfo from './DatasetInfo';
import DeviceInfo from './DeviceInfo';
import FlightDataList from './FlightDataList';

const BASE_URI = process.env.REACT_APP_BASE_API_URL;

const DatasetPage = () => {
  const { id: datasetId } = useParams();
  const [device, setDevice] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [flightData, setFlightData] = useState([]);

  useEffect(() => {
    // Fetch the device information
    fetch(`${BASE_URI}/device/${datasetId.split(':')[0]}`)
      .then((response) => response.json())
      .then((data) => setDevice(data))
      .catch((error) => console.error('Error fetching device:', error));

    // Fetch the dataset information
    fetch(`${BASE_URI}/dataset/${datasetId}`)
      .then((response) => response.json())
      .then((data) => setDataset(data))
      .catch((error) => console.error('Error fetching dataset:', error));

    // Fetch the list of flight data for the dataset
    fetch(`${BASE_URI}/dataset/${datasetId}/flight_datas`)
      .then((response) => response.json())
      .then((data) => setFlightData(data))
      .catch((error) => console.error('Error fetching flight data:', error));
  }, [datasetId]);

  return (
    <div class="container">
      <DeviceInfo device={device} />
      <DatasetInfo dataset={dataset} />
      <FlightDataList flightData={flightData} />
    </div>
  );
};

export default DatasetPage;